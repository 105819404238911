<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建</el-button>
      </div>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="false"
      :default-expand-all="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:action="{row}">
        <el-button type="text" @click='showEdit(row)'>修改</el-button>
        <el-popover
          v-if="!!row.service_contract_url || !!row.labor_contract_url || !!row.internship_contract_url || !!row.part_time_contract_url"
          placement="bottom"
          width="100"
          trigger="click">
          <div style="display:flex;flex-direction: column;">
            <el-button type="text" @click="openPdf(row.labor_contract_url)" v-show="!!row.labor_contract_url">劳动合同</el-button>
            <el-button type="text" @click="openPdf(row.service_contract_url)" v-show="!!row.service_contract_url">劳务合同</el-button>
            <el-button type="text" @click="openPdf(row.internship_contract_url)" v-show="!!row.internship_contract_url">实习协议</el-button>
            <el-button type="text" @click="openPdf(row.part_time_contract_url)" v-show="!!row.part_time_contract_url">兼职协议</el-button>
          </div>
          <el-button slot="reference" type="text" style="margin-left:10px;">合同模板</el-button>
        </el-popover>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>
  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import VTable from '@/components/VTable';
import { mixinTable } from "@/mixins/table.js";
import { invoiceCompanyList } from '@/api/finace.js'
export default {
  name: 'BillinginfoList',
  mixins: [mixinTable],
  components: {
    Edit,
    VTable
  },
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"50"},
        { name: "name", label: "用工单位", hidden: false },
        { name: "taxer_id_no", label: "纳税人识别号", hidden: false },
        { name: "bank_name", label: "开户银行", hidden: false},
        { name: "card_no", label: "账号", hidden: false},
        { name: "created_at", label: "创建时间", hidden: false},
        { name: "action", label: "操作",width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询开票企业列表
    getTable() {
      invoiceCompanyList(this.table.params).then(res => {
        this.table.data = res.data.list;
        this.table.total = res.data.total;
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    openPdf(url) {
      window.open(url)
    }
  }
}
</script>
<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='130px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <el-form-item prop='name' label='开票公司：'>
        <el-input
          v-model='form.data.name'
          clearable
          placeholder='请输入开票公司'
        ></el-input>
      </el-form-item>
      <el-form-item prop='taxer_id_no' label='纳税人识别号：'>
        <el-input
          v-model='form.data.taxer_id_no'
          clearable
          placeholder='请输入纳税人识别号'
        ></el-input>
      </el-form-item>
      <el-form-item prop='address' label='地址：'>
        <el-input
          v-model='form.data.address'
          clearable
          placeholder='请输入地址'
        ></el-input>
      </el-form-item>
      <el-form-item prop='phone' label='办公电话：'>
        <el-input
          v-model='form.data.phone'
          clearable
          placeholder='请输入办公电话'
        ></el-input>
      </el-form-item>
      <el-form-item prop='bank_name' label='开户银行：'>
        <el-input
          v-model='form.data.bank_name'
          clearable
          placeholder='请输入开户银行'
        ></el-input>
      </el-form-item>
      <el-form-item prop='card_no' label='账号：'>
        <el-input
          v-model='form.data.card_no'
          clearable
          placeholder='请输入卡号'
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'SystemUnitEdit',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            name: '',
            taxer_id_no: '',
            address: '',
            phone: '',
            bank_name: '',
            card_no: '',
          },
          rules: {
            name: [{required: true, message: "请输入开票公司！", trigger: "change"}],
            taxer_id_no: [{required: true, message: "请输入纳税人识别号！", trigger: "change"}],
            address: [{required: true, message: "请输入地址！", trigger: "change"}],
            phone: [{required: true, message: "请输入办公电话！", trigger: "change"}],
            bank_name: [{required: true, message: "请输入开户银行！", trigger: "change"}],
            card_no: [{required: true, message: "请输入卡号！", trigger: "change"}],
          }
        },
      }
    },
    methods: {
      // 获取组织详情
      getDetail(row) {
        this.isChange = true
        common.deepClone(this.form.data, row)
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.loading = true;
            let apiUrl = !this.isChange ? '/admin/InvoiceCompany/create' : '/admin/InvoiceCompany/edit'
           this.$http.post(apiUrl, this.form.data).then(res => {
             if(res.code === 1) {
              this.toggle(false)
              this.$emit('refresh')
            } else {
              this.$message.error(res.msg)
            }
           }).finally(() => {
             this.form.loading = false;
           })
          }
        })
      },
    }
  }
</script>
